import { FC, useState } from "react";
import BaseFont from "../../consts/base-font";
import { cn } from "@bem-react/classname";
import "./font-input-component.scss";
import { onFileInput } from "../../shared/helpers/rendering-fonts";

type FontInputProps = {
  font: BaseFont;
  text: string;
  OnChange: (index: number, value: string, file: any) => void;
  OnChangeString: (index: number, value: string) => void;
};

const cat = cn("container");
const FontInput: FC<FontInputProps> = ({
  font,
  OnChange,
  OnChangeString,
  text,
}) => {
  return (
    <div className={cat()}>
      <label className="input-file">
        <span className="input-file-text">{font.name}</span>
        <input
          type="file"
          name="file"
          onInput={(e: any) => {
            e.target?.files &&
              OnChange(
                font.id ? font.id : 0,
                e.target.files[0].name.split(".")[0],
                e.target.files[0]
              );
            onFileInput(e, e.target.files[0].name.split(".")[0]);
          }}
          accept={".ttf"}
        />
        <span className="input-file-btn">Выберите файл {text}</span>
      </label>
      <div className="group">
        <input
          type="text"
          required
          value={font.key}
          onChange={(e) => {
            OnChangeString(font.id ? font.id : 0, e.target.value);
          }}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label>Название {text}</label>
      </div>
    </div>
  );
};

export default FontInput;
