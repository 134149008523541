// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  display: flex;
  flex-direction: column;
  background: white;
  border: 5px solid white;
  border-radius: 8px;
  width: 250px;
  padding: 0px 20px;
}
.text > div {
  display: flex;
}
.text input {
  background-color: transparent;
  border: none;
  height: 15px;
  margin-bottom: 5px;
  font-size: 20px;
  margin-top: 3px;
}
.text :active,
.text :hover,
.text :focus {
  outline: 0;
  outline-offset: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/text-input-component/text-input-component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAAI;EACE,aAAA;AAEN;AAAI;EACE,6BAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;AAEN;AACI;;;EAGE,UAAA;EACA,iBAAA;AACN","sourcesContent":[".text {\n    display: flex;\n    flex-direction: column;\n    background: white;\n    border: 5px solid white;\n    border-radius: 8px;\n    width: 250px;\n    padding: 0px 20px;\n    > div {\n      display: flex;\n    }\n    input {\n      background-color: transparent;\n      border: none;\n      height: 15px;\n      margin-bottom: 5px;\n      font-size: 20px;\n      margin-top: 3px;\n    }\n\n    :active,\n    :hover,\n    :focus {\n      outline: 0;\n      outline-offset: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
