import { cn } from "@bem-react/classname";
import "./order-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ListOrdersComponent from "../list-orders-component/list-orders-component";
import Modifier from "../../../consts/modifier";
import Order from "../../../consts/order";
import ModifiersList from "../modifiers-list-component/modifiers-list-component";
import ModifiersGroup from "../modifiers-group-component/modifiers-group-component";

const img = require("./../../../shared/imges/orderImg.png");
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];

const sizes = [
  new Modifier(0, "Большой", 800),
  new Modifier(1, "Средний", 600),
  new Modifier(2, "Маленький", 300),
];

const modifiers_list = [
  new Modifier(0, "Сырный", 59),
  new Modifier(1, "Кисло-сладкий", 59),
  new Modifier(2, "Чесночный", 59),
];

const cat = cn("order-table");
type ListOrdeWithModalProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
  selectIndex: number;
  fontSizes: number[];
};
const OrderComponent: FC<ListOrdeWithModalProps> = ({
  colors,
  image,
  fonts,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div id="ListOrdeWithModalComponent" className={cat()}>
      <ListOrdersComponent
        fontSizes={fontSizes}
        selectIndex={selectIndex}
        img={image}
        fonts={fonts}
        colors={colors}
      />
      <div className={cat("modal")}>
        <div
          className={cat("", [selectIndex == 0 ? "backlight" : ""])}
          id="OrderComponent"
          style={{ backgroundColor: "#" + colors[0].color }}
        >
          <div className={cat("info")}>
            <div className="add-info">
              <div className="name">
                <p
                  className={selectIndex == 6 ? "backlighttext" : ""}
                  style={{
                    fontSize: fontSizes[2] + "px",
                    fontFamily: fonts[0].name,
                    color: "#" + colors[6].color,
                  }}
                >
                  {orders[0].name}
                </p>
              </div>
            </div>
            <div>
              <img src={img}></img>
            </div>
            <button
              className={selectIndex == 12 ? "backlight" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                backgroundColor: "#" + colors[12].color,
                fontFamily: fonts[0].name,
                color: "#" + colors[13].color,
              }}
            >
              Закрыть
            </button>
          </div>
          <div>
            <div className={cat("sizes")}>
              <p
                className={selectIndex == 6 ? "backlighttext" : ""}
                style={{
                  fontSize: fontSizes[2] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[6].color,
                }}
              >
                Размер бургера
              </p>
              <ModifiersGroup
                selectIndex={selectIndex}
                sizes={sizes}
                colors={colors}
                fonts={fonts}
                fontSizes={fontSizes}
              />
            </div>

            <div className={cat("modifiers")}>
              <ModifiersList
                fontSizes={fontSizes}
                modifies={modifiers_list}
                selectIndex={selectIndex}
                colors={colors}
                fonts={fonts}
                text={"Соусы"}
              />
              <ModifiersList
                fontSizes={fontSizes}
                modifies={modifiers_list}
                selectIndex={selectIndex}
                colors={colors}
                fonts={fonts}
                text={"Дополнительно"}
              />
            </div>
          </div>
          <div>
            <p
              className={selectIndex == 6 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[6].color,
              }}
            >
              934 ₽
            </p>
            <div className={cat("count")}>
              <p
                className={selectIndex == 6 ? "backlighttext" : ""}
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[6].color,
                }}
              >
                Выберите количество
              </p>
              <div
                className={"count " + (selectIndex == 10 ? "backlight" : "")}
                style={{ backgroundColor: "#" + colors[10].color }}
              >
                <span
                  className={selectIndex == 11 ? "backlighttext" : ""}
                  style={{
                    fontSize: fontSizes[0] + "px",
                    fontFamily: fonts[0].name,
                    color: "#" + colors[11].color,
                  }}
                >
                  -
                </span>
                <span
                  className={selectIndex == 11 ? "backlighttext" : ""}
                  style={{
                    fontSize: fontSizes[0] + "px",
                    fontFamily: fonts[0].name,
                    color: "#" + colors[11].color,
                  }}
                >
                  1
                </span>
                <span
                  className={selectIndex == 11 ? "backlighttext" : ""}
                  style={{
                    fontSize: fontSizes[0] + "px",
                    fontFamily: fonts[0].name,
                    color: "#" + colors[11].color,
                  }}
                >
                  +
                </span>
              </div>
            </div>
            <div className={cat("count")}>
              <p
                className={selectIndex == 6 ? "backlighttext" : ""}
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[6].color,
                }}
              >
                Общая стоимость
              </p>
              <p
                className={selectIndex == 6 ? "backlighttext" : ""}
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[6].color,
                }}
              >
                934 ₽
              </p>
            </div>
            <div className={cat("count")}>
              <button
                className={selectIndex == 12 ? "backlight" : ""}
                style={{
                  fontSize: fontSizes[0] + "px",
                  backgroundColor: "#" + colors[12].color,
                  fontFamily: fonts[0].name,
                  color: "#" + colors[13].color,
                }}
              >
                Добавить в заказ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderComponent;
