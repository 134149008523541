// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-left: 10px;
  height: max-content;
}`, "",{"version":3,"sources":["webpack://./src/components/orders-group-component/orders-group-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".orders {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 10px;\n  row-gap: 10px;\n  margin-left: 10px;\n  height: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
