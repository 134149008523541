import { cn } from "@bem-react/classname";
import "./order-result-component.scss";
import { FC } from "react";
import { fonts } from "pdfmake/build/pdfmake";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ResultItemComponent from "../result-item-component/result-item-component";

const img = require("./../../../shared/imges/orderImg.png");
const cat = cn("order_result-table");
type ListOrderProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
  fontSizes: number[];
  selectIndex: number;
};
const OrderResultComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  image,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id="OrderResultComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <div className={cat("top")}>
        <p
          className={selectIndex == 6 ? "backlighttext" : ""}
          style={{
            fontSize: fontSizes[0] + "px",
            fontFamily: fonts[0].name,
            color: "#" + colors[6].color,
          }}
        >
          Ваш заказ
        </p>
        <div
          className={selectIndex == 6 ? "backlighttext" : ""}
          style={{
            fontSize: fontSizes[0] + "px",
            fontFamily: fonts[0].name,
            color: "#" + colors[6].color,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
          </svg>
          Назад
        </div>
      </div>
      <div>
        <div className={cat("goods")}>
          <ResultItemComponent
            fontSizes={fontSizes}
            selectIndex={selectIndex}
            fonts={fonts}
            colors={colors}
          />
          <ResultItemComponent
            fontSizes={fontSizes}
            selectIndex={selectIndex}
            fonts={fonts}
            colors={colors}
          />
          <ResultItemComponent
            fontSizes={fontSizes}
            selectIndex={selectIndex}
            fonts={fonts}
            colors={colors}
          />
        </div>
        <div className={cat("res")}>
          <p
            className={selectIndex == 6 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
            }}
          >
            Общая стоимость:
          </p>
          <p
            className={selectIndex == 6 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
            }}
          >
            1024 ₽
          </p>
        </div>

        <div className={cat("buttons")}>
          <button
            className={"back " + (selectIndex == 12 ? "backlight" : "")}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[12].color,
            }}
          >
            Назад
          </button>
          <button
            className={
              selectIndex == 12
                ? "backlight"
                : selectIndex == 13
                ? "backlighttext"
                : ""
            }
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              backgroundColor: "#" + colors[12].color,
              color: "#" + colors[13].color,
            }}
          >
            Оплатить
          </button>
        </div>
      </div>
    </div>
  );
};
export default OrderResultComponent;
