// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  row-gap: 5px;
  margin: auto;
  max-width: 900px;
}
.form > p {
  margin: 0px;
  font-size: 14px;
  color: whitesmoke;
}
.form-head {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
}
.form-head h2 {
  color: whitesmoke;
}
.form-head img {
  width: 200px;
  height: 40px;
  margin-top: 8px;
}
.form-block {
  background: whitesmoke;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px 20px;
}
.form-block > div {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  column-gap: 10px;
}
.form-block > div > div {
  width: 100%;
}
.form-buttons {
  display: flex;
  column-gap: 10px;
}
.form .error,
.form .error .css-1jqq78o-placeholder,
.form .required {
  color: red !important;
}`, "",{"version":3,"sources":["webpack://./src/components/form-component/form-component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;EACA,iBAAA;AAEJ;AAAE;EACE,aAAA;EACA,gBAAA;EACA,8BAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AADI;EACE,YAAA;EACA,YAAA;EACA,eAAA;AAGN;AAAE;EACE,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAEJ;AADI;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;AAGN;AAFM;EACE,WAAA;AAIR;AAAE;EACE,aAAA;EACA,gBAAA;AAEJ;AAAE;;;EAGE,qBAAA;AAEJ","sourcesContent":[".form {\n  width: -webkit-fill-available;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  row-gap: 5px;\n  margin: auto;\n  max-width: 900px;\n  > p {\n    margin: 0px;\n    font-size: 14px;\n    color: whitesmoke;\n  }\n  &-head {\n    display: flex;\n    column-gap: 10px;\n    justify-content: space-between;\n    h2 {\n      color: whitesmoke;\n    }\n    img {\n      width: 200px;\n      height: 40px;\n      margin-top: 8px;\n    }\n  }\n  &-block {\n    background: whitesmoke;\n    border: 1px solid;\n    border-radius: 10px;\n    padding: 10px 20px;\n    > div {\n      margin-top: 15px;\n      display: flex;\n      flex-direction: column;\n      row-gap: 5px;\n      column-gap: 10px;\n      > div {\n        width: 100%;\n      }\n    }\n  }\n  &-buttons {\n    display: flex;\n    column-gap: 10px;\n  }\n  .error,\n  .error .css-1jqq78o-placeholder,\n  .required {\n    color: red !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
