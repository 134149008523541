import { cn } from "@bem-react/classname";
import "./result-item-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";

const img = require("./../../../shared/imges/orderImg.png");
const cat = cn("result_item-horizontal");
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];
type ListOrderProps = {
  colors: BaseColor[];
  fonts: BaseColor[];
  selectIndex: number;
  fontSizes: number[];
};
const ResultItemComponent: FC<ListOrderProps> = ({
  colors,
  fontSizes,
  fonts,
  selectIndex,
}) => {
  return (
    <div className={cat()} style={{ borderColor: "#" + colors[0].color }}>
      {" "}
      <div>
        <img src={img} />
        <div>
          <p
            className={selectIndex == 6 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
            }}
          >
            {orders[1].name}
          </p>
          <p
            className={"small " + (selectIndex == 6 ? "backlighttext" : "")}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
            }}
          >
            большой, сырный, маринованные огурцы
          </p>
        </div>
      </div>
      <div className="res" style={{ color: "#" + colors[6].color }}>
        <span
          className={selectIndex == 6 ? "backlighttext" : ""}
          style={{
            fontSize: fontSizes[0] + "px",
            fontFamily: fonts[0].name,
            color: "#" + colors[6].color,
          }}
        >
          -
        </span>
        <span
          className={selectIndex == 6 ? "backlighttext" : ""}
          style={{
            fontSize: fontSizes[0] + "px",
            fontFamily: fonts[0].name,
            color: "#" + colors[6].color,
          }}
        >
          1
        </span>
        <span
          className={selectIndex == 6 ? "backlighttext" : ""}
          style={{
            fontSize: fontSizes[0] + "px",
            fontFamily: fonts[0].name,
            color: "#" + colors[6].color,
          }}
        >
          +
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
    </div>
  );
};
export default ResultItemComponent;
