// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: -webkit-fill-available;
}
.list .modif {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: -webkit-fill-available;
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 5px;
}
.list .modif > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list .modif > div > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 63px;
  position: relative;
  bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-ansoftbase/modifiers-list-component/modifiers-list-component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACN;AAAM;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AAER","sourcesContent":[".list {\n  width: -webkit-fill-available;\n\n  .modif {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n    width: -webkit-fill-available;\n    padding: 5px 10px;\n    border: 1px solid;\n    border-radius: 5px;\n\n    > div {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      > div {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        width: 63px;\n        position: relative;\n        bottom: 10px;\n    \n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
