// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  width: 300px;
  height: 50px;
  margin-top: 10px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  background: #0b63f6;
  box-shadow: 0 5px 0 #003cc5;
}

.button:hover {
  background: #003cc5;
  box-shadow: none;
  position: relative;
  top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/button-component/button-component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;EACA,2BAAA;AACF;;AACA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;AAEF","sourcesContent":[".button {\n  width: 300px;\n  height: 50px;\n  margin-top: 10px;\n  font-size: 15px;\n  border: none;\n  border-radius: 10px;\n  text-decoration: none;\n  color: white;\n  background: #0b63f6;\n  box-shadow: 0 5px 0 #003cc5;\n}\n.button:hover {\n  background: #003cc5;\n  box-shadow: none;\n  position: relative;\n  top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
