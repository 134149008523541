// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
  font-size: 14px;
}
.select .css-13cymwt-control {
  height: 40px;
}
.select .css-9jq23d {
  padding: 1px 1px 2px 5px;
}
.select .css-1p3m7a8-multiValue {
  margin-top: 16px;
}

.select .css-1nmdiq5-menu {
  z-index: 5;
}

.placeholder {
  position: relative;
  width: fit-content;
  top: 11px;
  left: 0.8px;
  z-index: 5;
  visibility: hidden;
  height: 0px;
  margin: 0px 50px 0px 10px;
  font-size: 14px;
  color: transparent;
  transition: top 0.8s;
  transition: color 0.1s;
  z-index: 1;
}

.helper {
  position: relative;
  font-size: 10px;
  top: -20px;
  margin: 0px;
  color: var(--color-ink-medium-contrast);
  height: auto;
  visibility: visible;
  z-index: 0;
}

.select:not(:has(.css-hlgwow .css-1jqq78o-placeholder)) .placeholder {
  top: 1px;
  left: 0.8px;
  visibility: visible;
  color: var(--color-ink-medium-contrast);
  font-size: 12px;
  transition: top 0.8s;
  transition: color 0.1s;
}

.select:not(:has(.css-hlgwow .css-1jqq78o-placeholder)) .css-hlgwow {
  padding: 12px 8px 2px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/select-component/select-component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,wBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,UAAA;AAGF;;AADA;EACE,kBAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,uCAAA;EACA,YAAA;EACA,mBAAA;EACA,UAAA;AAIF;;AAFA;EACE,QAAA;EACA,WAAA;EACA,mBAAA;EAEA,uCAAA;EACA,eAAA;EACA,oBAAA;EACA,sBAAA;AAIF;;AADA;EACE,yBAAA;AAIF","sourcesContent":[".select {\n  font-size: 14px;\n  .css-13cymwt-control {\n    height: 40px;\n  }\n  .css-9jq23d {\n    padding: 1px 1px 2px 5px;\n  }\n  .css-1p3m7a8-multiValue {\n    margin-top: 16px;\n  }\n}\n.select .css-1nmdiq5-menu{\n  z-index: 5;\n}\n.placeholder {\n  position: relative;\n  width: fit-content;\n  top: 11px;\n  left: 0.8px;\n  z-index: 5;\n  visibility: hidden;\n  height: 0px;\n  margin: 0px 50px 0px 10px;\n  font-size: 14px;\n  color: transparent;\n  transition: top 0.8s;\n  transition: color 0.1s;\n  z-index: 1;\n}\n.helper {\n  position: relative;\n  font-size: 10px;\n  top: -20px;\n  margin: 0px;\n  color: var(--color-ink-medium-contrast);\n  height: auto;\n  visibility: visible;\n  z-index: 0;\n}\n.select:not(:has(.css-hlgwow .css-1jqq78o-placeholder)) .placeholder {\n  top: 1px;\n  left: 0.8px;\n  visibility: visible;\n\n  color: var(--color-ink-medium-contrast);\n  font-size: 12px;\n  transition: top 0.8s;\n  transition: color 0.1s;\n}\n\n.select:not(:has(.css-hlgwow .css-1jqq78o-placeholder)) .css-hlgwow {\n  padding: 12px 8px 2px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
