import { FC, useState } from "react";
import BaseColor from "../../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./params-group-component.scss";
import BaseFont from "../../../consts/base-font";
import Modifier from "../../../consts/modifier";

type ParamsGroupProps = {
  params: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
  selectIndex: number;
  fontSizes: number[];
};

const cat = cn("params");
const ParamsGroup: FC<ParamsGroupProps> = ({
  params,
  colors,
  fonts,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 3 ? "backlight" : ""])}
      style={{ borderColor: "#" + colors[3].color }}
    >
      {params.map((c) => {
        return (
          <div key={crypto.randomUUID()}>
            <span
              className={selectIndex == 6 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[6].color,
              }}
            >
              {c.name}
            </span>
            <span
              className={selectIndex == 6 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[6].color,
              }}
            >
              {c.price}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ParamsGroup;
