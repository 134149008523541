// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-order-with-basket {
  display: grid;
  grid-template-rows: 1fr;
}
.list-order-with-basket > div {
  display: flex;
  margin-top: 20px;
  padding-left: 20px;
}
.list-order-with-basket > div:first-child {
  padding: 30px 10px;
  column-gap: 10px;
}
.list-order-with-basket-bottom {
  height: 100px;
  flex-direction: column;
  height: 269px;
  position: relative;
  bottom: 191px;
}
.list-order-with-basket-bottom .top {
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 26px;
  height: 50px;
  box-sizing: border-box;
  position: relative;
  bottom: -10px;
}
.list-order-with-basket-bottom .top > div {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.list-order-with-basket-bottom .buttons {
  display: flex;
  justify-content: space-around;
  padding: 0px 15px;
  bottom: -10px;
  position: relative;
  padding-left: 0px;
}
.list-order-with-basket-bottom .buttons button {
  height: 30px;
  width: 110px;
  border: 1px;
  border-radius: 5px;
}
.list-order-with-basket-bottom .buttons .pay_button {
  width: 140px;
}
.list-order-with-basket-bottom .orders {
  display: flex;
  padding-top: 15px;
  box-sizing: border-box;
  column-gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/list-orders-with-basket-component/list-orders-with-basket-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AAAE;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,gBAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAAI;EACE,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;AAEN;AADM;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAGR;AAAI;EACE,aAAA;EACA,6BAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AAEN;AADM;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAGR;AADM;EACE,YAAA;AAGR;AAAI;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;AAEN","sourcesContent":[".list-order-with-basket {\n  display: grid;\n  grid-template-rows: 1fr;\n  > div {\n    display: flex;\n    margin-top: 20px;\n    padding-left: 20px;\n  }\n  > div:first-child {\n    padding: 30px 10px;\n    column-gap: 10px;\n  }\n \n  &-bottom {\n    height: 100px;\n    flex-direction: column;\n    height: 269px;\n    position: relative;\n    bottom: 191px;\n    .top {\n      padding-left: 5px;\n      display: flex;\n      justify-content: space-between;\n      width: 100%;\n      padding: 10px 26px;\n      height: 50px;\n      box-sizing: border-box;\n      position: relative;\n      bottom: -10px;\n      > div {\n        display: flex;\n        align-items: center;\n        column-gap: 5px;\n      }\n    }\n    .buttons {\n      display: flex;\n      justify-content: space-around;\n      padding: 0px 15px;\n      bottom: -10px;\n      position: relative;\n      padding-left: 0px;\n      button {\n        height: 30px;\n        width: 110px;\n        border: 1px;\n        border-radius: 5px;\n      }\n      .pay_button {\n        width: 140px;\n      }\n    }\n    .orders {\n      display: flex;\n      padding-top: 15px;\n      box-sizing: border-box;\n      column-gap: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
