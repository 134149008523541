import { FC, useState } from "react";
import BaseColor from "../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./params-group-component.scss";
import BaseFont from "../../consts/base-font";
import Modifier from "../../consts/modifier";

type ParamsGroupProps = {
  params: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
};

const cat = cn("params");
const ParamsGroup: FC<ParamsGroupProps> = ({ params, colors, fonts }) => {
  return (
    <div className={cat()} style={{ borderColor: "#" + colors[3].color }}>
      {params.map((c) => {
        return (
          <div key={crypto.randomUUID()}>
          <span
            style={{
              fontFamily: fonts[0].name,
              color: "#" + colors[4].color,
            }}
          >
            {c.name}
          </span>
          <span
            style={{
              fontFamily: fonts[0].name,
              color: "#" + colors[4].color,
            }}
          >
            {c.price}
          </span>
        </div>
        );
      })}   
    </div>
  );
};

export default ParamsGroup;
