// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sizes {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.sizes > div {
  width: 91px;
  height: 45px;
  border: 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.sizes > div p {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/modifiers-group-component/modifiers-group-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AAEJ;AADI;EACE,kBAAA;AAGN","sourcesContent":[".sizes {\n  display: flex;\n  column-gap: 10px;\n  align-items: center;\n  > div {\n    width: 91px;\n    height: 45px;\n    border: 1px solid;\n    border-radius: 5px;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    p {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
