import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./text-input-component.scss";

type TextInputProps = {
  title: string;
  text: string;
  OnChange: (value: string) => void;
};

const cat = cn("text");
const TextInput: FC<TextInputProps> = ({ title, text, OnChange }) => {
  return (
    <div className={cat()}>
      <span>{title}</span>
      <div>
        <input value={text} onChange={(e) => OnChange(e.target.value)} />
      </div>
    </div>
  );
};

export default TextInput;
