import { SyntheticEvent } from "react";

export const convertFontFiletoBase64 = async (file: File) => {
  const result_base64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return result_base64 as string;
};

export const onFileInput = async (
  e: SyntheticEvent<HTMLInputElement, Event>, name:string
) => {
  const { files } = e.currentTarget;
  if (files && files?.length > 0) {
    const font = await convertFontFiletoBase64(files[0]);

    // @ts-ignore
    const fontFace = new FontFace(name, `url(${font})`);
    (document as any).fonts.add(fontFace);
    await fontFace.load();
  }
};
