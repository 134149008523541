// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-page-vertical {
  position: relative;
  height: 800px;
}
.modal-page-vertical > img {
  width: 100%;
  height: 100px;
}
.modal-page-vertical-modal {
  top: 0px;
  display: flex;
  position: absolute;
  background-color: rgba(184, 184, 184, 0.6);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.modal-page-vertical-modal > div {
  border: 1px;
  border-radius: 15px;
  width: 350px;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-page-vertical-modal > div > p {
  padding: 0px 20px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 25px;
}
.modal-page-vertical-buttons {
  display: flex;
  height: -webkit-fill-available;
  align-items: center;
  padding-left: 0px;
  justify-content: center;
  column-gap: 10px;
}
.modal-page-vertical-buttons > button {
  border-radius: 3px;
  border: none;
  height: 40px;
  width: 150px;
  box-shadow: -1px 1px 4px #3a3a3a;
}
.modal-page-vertical-buttons > .back {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/template-vertical/list-orders-with-modal-component/list-orders-with-modal-component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF;AAAE;EACE,WAAA;EACA,aAAA;AAEJ;AAAE;EACE,QAAA;EACA,aAAA;EACA,kBAAA;EACA,0CAAA;EACA,6BAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AAEJ;AADI;EACE,WAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAGN;AAFM;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAIR;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,gCAAA;AAGN;AADI;EACE,6BAAA;AAGN","sourcesContent":[".modal-page-vertical {\n  position: relative;\n  height: 800px;\n  > img {\n    width: 100%;\n    height: 100px;\n  }\n  &-modal {\n    top: 0px;\n    display: flex;\n    position: absolute;\n    background-color: rgba(184, 184, 184, 0.6);\n    width: -webkit-fill-available;\n    height: -webkit-fill-available;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 100px;\n    > div {\n      border: 1px;\n      border-radius: 15px;\n      width: 350px;\n      height: 260px;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      > p {\n        padding: 0px 20px;\n        text-align: center;\n        margin-top: 60px;\n        margin-bottom: 25px;\n      }\n    }\n  }\n  &-buttons {\n    display: flex;\n    height: -webkit-fill-available;\n    align-items: center;\n    padding-left: 0px;\n    justify-content: center;\n    column-gap: 10px;\n    > button {\n      border-radius: 3px;\n      border: none;\n      height: 40px;\n      width: 150px;\n      box-shadow: -1px 1px 4px #3a3a3a;\n    }\n    > .back {\n      background-color: transparent;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
