class BaseFont {
  id?: number;
  name?: string;
  key?: string;
  file?: any;
  constructor(
    id: number,
    name: string = "Roboto",
    key: string = "Roboto",
    file: any = undefined
  ) {
    this.id = id;
    this.name = name;
    this.file = file;
    this.key = key;
  }
}
export default BaseFont;
