import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./modifiers-group-component.scss";
import Modifier from "../../../consts/modifier";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";

type ModifiersGroupProps = {
  sizes: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
  selectIndex: number;
  fontSizes: number[];
};

const cat = cn("sizes-table");
const ModifiersGroup: FC<ModifiersGroupProps> = ({
  sizes,
  colors,
  fonts,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div className={cat()}>
      {sizes.map((c) => {
        return (
          <div
            className={selectIndex == 3 ? "backlight" : ""}
            key={c.id}
            style={{
              borderColor:
                c.id == 0 ? "#" + colors[7].color : "#" + colors[6].color,
            }}
          >
            <p
              className={
                (selectIndex == 8 && c.id == 0) ||
                (selectIndex == 6 && c.id != 0)
                  ? "backlighttext"
                  : ""
              }
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + (c.id == 0 ? colors[7].color : colors[6].color),
              }}
            >
              {c.name}
            </p>
            {c.price && (
              <p
                className={
                  (selectIndex == 8 && c.id == 0) ||
                  (selectIndex == 6 && c.id != 0)
                    ? "backlighttext"
                    : ""
                }
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + (c.id == 0 ? colors[7].color : colors[6].color),
                }}
              >
                {c.price}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ModifiersGroup;
