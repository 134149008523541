import "./App.css";
import { useEffect, useState } from "react";
import ColorPicker from "./components/color-picker-component/color-picker-component";
import PageComponent from "./components/page-component/page-component";
import BaseColor from "./consts/base-colors";
import BaseFont from "./consts/base-font";
import BlockForm from "./consts/block-form";
import axios from "axios";
import { Route, Routes } from "react-router-dom";
import Form from "./components/form-component/form-component";

let base_colors = [
  new BaseColor(0, "Фон", "d2ddff"),
  new BaseColor(1, "Карточка товара", "e4e4ff"),
  new BaseColor(2, "Текст карточки товара", "5981ba"),
  new BaseColor(3, "Доп. элементы/рамочки", "03197c"),
  new BaseColor(4, "Основной текст", "0c0c80"),
  new BaseColor(5, "Текст выбранного элемента", "ffffff"),
  new BaseColor(6, "Доп. панели", "466abd"),
  new BaseColor(7, "Текст доп. панели", "ffffff"),
  new BaseColor(8, "Доп. некоторые элементы", "3234ad"),
  new BaseColor(9, "Текст некоторых элементов", "ffffff"),
  new BaseColor(10, "Кнопки подтверждения действий", "18107c"),
  new BaseColor(11, "Текст кнопки", "ffffff"),
];

let base_fonts = [new BaseFont(0), new BaseFont(1)];
const App = () => {
  const [colors, setColors] = useState(base_colors);
  const [fonts, setFonts] = useState(base_fonts);

  const [form, setForm] = useState<BlockForm[]>([]);
  const [banner, setBanner] = useState<any>("./top.png");
  const [stub, setStub] = useState<any>();

  useEffect(() => {
    axios.get("/form.json").then((r) => {
      setForm(r.data);
    });
  }, [1]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Form form={form} setForm={setForm} />} />
        <Route
          path="/template"
          element={
            <>
              <ColorPicker
                fonts={fonts}
                colors={colors}
                setFonts={setFonts}
                setColors={setColors}
                setBanner={setBanner}
                setStub={setStub}
              />
              <PageComponent
                stub={stub}
                banner={banner}
                fonts={fonts}
                colors={colors}
              />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
