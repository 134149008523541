import { cn } from "@bem-react/classname";
import "./result-item-component.scss";
import { FC } from "react";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
import { fonts } from 'pdfmake/build/pdfmake';

const img = require("./../../shared/imges/orderImg.png");
const cat = cn("result_item");
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];
type ListOrderProps = {
  colors: BaseColor[];
  fonts:BaseColor[];
};
const ResultItemComponent: FC<ListOrderProps> = ({ colors, fonts }) => {
  return (
    <div className={cat()} style={{ borderColor: "#" + colors[0].color }}>
      {" "}
      <div>
        <img src={img} />
        <div>
          <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>{orders[1].name}</p>
          <p style={{ fontFamily: fonts[1].name, color: "#" + colors[4].color }} className="small">
            большой, сырный, маринованные огурцы
          </p>
        </div>
      </div>
      <div className="res">
        <div>
          <span style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }} className="small">
            -
          </span>
          <span style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }} className="small">
            1
          </span>
          <span style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }} className="small">
            +
          </span>
        </div>
        <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>934 ₽</p>
      </div>
    </div>
  );
};
export default ResultItemComponent;
