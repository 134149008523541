import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./form-component.scss";
import BlockForm from "../../consts/block-form";
import ItemForm from "../../consts/Item-form";
import { TypeItem } from "../../consts/Item-form";
import FormInput from "../form-input-component/form-input-component";
import Button from "../button-component/button-component";
import {
  DonloadForm,
  DonloadJson,
} from "../../shared/helpers/create-colors-sheet";
import { useNavigate } from "react-router-dom";
import SelectForm from "../select-component/select-component";
import BoolForm from "../bool-component/bool-component";
import FormFileInput from "../form-file-input-component/form-file-input-component";
import Modal from "../modal-component/modal-component";

type FormProps = {
  form: BlockForm[];
  setForm: (value: BlockForm[]) => void;
};
const cat = cn("form");
const Form: FC<FormProps> = ({ form, setForm }) => {
  let navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const changeValueForm = (item: ItemForm, value: string) => {
    item.value = value;
    if (item.isError == true) item.isError = false;
    setForm([...form]);
  };
  const ValidationChild = (list: ItemForm[], key?: string) => {
    let result = true;
    if (list == undefined) return result;
    list.map((c: ItemForm, j) => {
      if (
        ((key && c.parent && (key == c.parent || key.includes(c.parent))) ||
          (!key && !c.parent)) &&
        c.required &&
        (c.value == "" || !c.value) &&
        c.type != TypeItem.Bool
      ) {
        console.log(c);
        c.isError = true;
        result = false;
      }
      if (c.children && ValidationChild(c.children, c.value) == false)
        result = false;
    });
    return result;
  };

  const ValidationData = () => {
    let result = true;
    let er = form;
    er.map((f) => {
      if (f.content && ValidationChild(f.content) == false) result = false;
    });
    setForm([...er]);
    return result;
  };

  const getItemsList = (list: ItemForm[], key?: string) => {
    let result: any[] = [];
    if (list == undefined) return result;
    list.map((c: ItemForm, j) => {
      ((key && c.parent && (key == c.parent || key.includes(c.parent))) ||
        (!key && !c.parent)) &&
        result.push(
          c.type == TypeItem.String || c.type == TypeItem.Time ? (
            <FormInput
              helper={c.description}
              isError={c.isError}
              time={c.type == TypeItem.Time}
              key={j.toString() + c.name}
              isRequired={c.required}
              title={c.name}
              text={c.value}
              OnChange={(v) => {
                changeValueForm(c, v);
              }}
            />
          ) : c.type == TypeItem.Radio || c.type == TypeItem.Combo ? (
            <SelectForm
              helper={c.description}
              isError={c.isError}
              key={c.name}
              isMulti={c.type == TypeItem.Combo}
              placeholder={c.name}
              isRequired={c.required}
              options={c.variants}
              onChange={(v) => {
                changeValueForm(c, v);
              }}
              value={c.value ? c.value.split(",") : []}
            />
          ) : (
            c.type == TypeItem.Bool && (
              <BoolForm
                placeholder={c.name}
                onChange={(v) => {
                  changeValueForm(c, v);
                }}
                value={c.value}
                helper={c.description}
                isError={c.isError}
                key={c.name}
              />
            )
          )
        );
      let child = getItemsList(c.children, c.value);
      result.push(child);
    });
    return result;
  };

  const loadJson = (file: any) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(file);
  };
  function onReaderLoad(event: any) {
    var obj = JSON.parse(event.target.result);
    setForm(obj);
  }

  return (
    <div className={cat()}>
      <div className={cat("head")}>
        <h2>Заполнение Чек-листа по запуску нового Киоска</h2>
        <img src="/logo.png" />
      </div>
      <FormFileInput
        text={"Загрузить чек-лист"}
        accept=".json"
        OnChange={(file) => {
          loadJson(file);
        }}
      />
      <p>*Для того, чтобы увидеть подсказку, необходимо навести на поле</p>
      {form.map((f: BlockForm, i) => {
        if (f.name) {
          return (
            <div key={i + "block"} className={cat("block")}>
              <summary>{f.name}</summary>
              <div>{getItemsList(f.content)}</div>
            </div>
          );
        } else {
          return (
            <div key={i + "block"} className={cat("block")}>
              <div>{getItemsList(f.content)}</div>
            </div>
          );
        }
      })}
      <div className={cat("buttons")}>
        <Button
          onClick={async () => {
            DonloadJson(form);
          }}
          text={"Сохранить черновик"}
        />
        <Button
          onClick={async () => {
            let r = ValidationData();
            r == true
              ? DonloadForm(form)
              : alert("Не все обязательные поля заполенены!");
          }}
          text={"Сохранить и завершить чек-лист"}
        />
        <Button
          onClick={() => setIsModalOpen(true)}
          text={"Перейти к шаблону брифа"}
        />
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>Не забудьте сохранить чек-лист!</h2>
        <p>При сохранении шаблона чек-лист не сохранится</p>
        <button className="button" onClick={() => navigate("/template")}>
          Перейти к шаблону
        </button>
      </Modal>
    </div>
  );
};

export default Form;
