import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./mini-card-order-component.scss";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";
import BaseFont from "../../../consts/base-font";
const cat = cn("mini-card-order");
const logo = require("./../../../shared/imges/orderImg.png");

type CardOrderProps = {
  colors: BaseColor[];
  order: Order;
  fonts: BaseFont[];
  fontSizes: number[];
  selectIndex: number;
};
const MiniCardOrderComponent: FC<CardOrderProps> = ({
  colors,
  order,
  fonts,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div className={cat("")}>
      <div className={selectIndex == 1 ? "backlight" : ""}>
        <div>
          <p
            className={selectIndex == 2 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[2].color,
            }}
          >
            {order.name}
          </p>
        </div>
        <div>
          <img src={logo} className={cat("img")}></img>
        </div>
        <div
          className={cat("count", [selectIndex == 10 ? "backlight" : ""])}
          style={{ backgroundColor: "#" + colors[10].color }}
        >
          <span
            className={selectIndex == 11 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[11].color,
            }}
          >
            -
          </span>
          <span
            className={selectIndex == 11 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[11].color,
            }}
          >
            1
          </span>
          <span
            className={selectIndex == 11 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[11].color,
            }}
          >
            +
          </span>
        </div>
      </div>
      <button
        className={
          selectIndex == 12
            ? "backlight"
            : selectIndex == 13
            ? "backlighttext"
            : ""
        }
        style={{
          fontSize: fontSizes[0] + "px",
          fontFamily: fonts[0].name,
          backgroundColor: "#" + colors[12].color,
          color: "#" + colors[13].color,
        }}
      >
        Настроить
      </button>
    </div>
  );
};
export default MiniCardOrderComponent;
