import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./form-file-input-component.scss";

type InputProps = {
  text: string;
  OnChange?: (file: any) => void;
  OnChangeGroup?: (files: any[]) => void;
  accept: string;
  multiple?: boolean;
};

const cat = cn("form-file");
const FormFileInput: FC<InputProps> = ({
  OnChange,
  OnChangeGroup,
  text,
  accept,
  multiple = false,
}) => {
  const [name, setName] = useState("");
  return (
    <div className={cat()}>
      <label className={cat("input-file")}>
        <p className={cat("input-file-text")}>{name}</p>
        <input
          multiple={multiple}
          type="file"
          name="file"
          onInput={(e: any) => {
            e.target?.files && setName(e.target.files[0].name);
            e.target?.files && OnChange && OnChange(e.target.files[0]);
            e.target?.files && OnChangeGroup && OnChangeGroup(e.target.files);
          }}
          accept={accept}
        />
        <p className={cat("input-file-btn")}>{text}</p>
      </label>
    </div>
  );
};

export default FormFileInput;
