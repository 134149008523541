import { cn } from "@bem-react/classname";
import "./order-component.scss";
import { FC } from "react";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
import BaseFont from "../../consts/base-font";
import ModifiersGroup from "../modifiers-group-component/modifiers-group-component";
import Modifier from "../../consts/modifier";
import ParamsGroup from "../params-group-component/params-group-component";
import ModifiersList from "../modifiers-list-component/modifiers-list-component";

const img = require("./../../shared/imges/orderImg.png");
const cat = cn("order");
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];

const sizes = [
  new Modifier(0, "Большой", 800),
  new Modifier(1, "Средний", 600),
  new Modifier(2, "Маленький", 300),
];
const params = [
  new Modifier(0, "Углеводы", 800),
  new Modifier(1, "Жиры", 600),
  new Modifier(2, "Белки", 300),
  new Modifier(2, "Ккла", 300),
  new Modifier(2, "Вес", 300),
];
const modifiers = [
  new Modifier(0, "Соусы"),
  new Modifier(1, "Специи"),
  new Modifier(2, "Состав"),
];
const modifiers_list = [
  new Modifier(0, "Сырный", 59),
  new Modifier(1, "Кисло-сладкий", 59),
  new Modifier(2, "Чесночный", 59),
  new Modifier(3, "Кетчуп", 59),
];
type ListOrderProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
};
const OrderComponent: FC<ListOrderProps> = ({ colors, fonts, image }) => {
  return (
    <div
      className={cat()}
      id="OrderComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={image}></img>
      <div className={cat("info")}>
        <div>
          <img src={img}></img>
          <p
            style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
          >
            {orders[0].name}
          </p>
        </div>
        <div className="add-info">
          <div className="name">
            <p
              style={{
                fontFamily: fonts[0].name,
                color: "#" + colors[4].color,
              }}
            >
              {orders[0].name}
            </p>
            <div style={{ backgroundColor: "#" + colors[8].color }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <g fill="none" fillRule="nonzero">
                  <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                  <path
                    fill={"#" + colors[9].color}
                    d="M3.283 10.94a1.5 1.5 0 0 0 0 2.12l5.656 5.658a1.5 1.5 0 1 0 2.122-2.122L7.965 13.5H19.5a1.5 1.5 0 0 0 0-3H7.965l3.096-3.096a1.5 1.5 0 1 0-2.122-2.121L3.283 10.94Z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <p
            style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
          >
            Размер бургера
          </p>
          <ModifiersGroup sizes={sizes} colors={colors} fonts={fonts} />
        </div>
      </div>
      <div className={cat("additional")}>
        <div
          className={"count"}
          style={{ backgroundColor: "#" + colors[8].color }}
        >
          <span
            style={{ fontFamily: fonts[0].name, color: "#" + colors[9].color }}
          >
            -
          </span>
          <span
            style={{ fontFamily: fonts[0].name, color: "#" + colors[9].color }}
          >
            1
          </span>
          <span
            style={{ fontFamily: fonts[0].name, color: "#" + colors[9].color }}
          >
            +
          </span>
        </div>
        <ModifiersGroup sizes={modifiers} colors={colors} fonts={fonts} />
      </div>
      <div className={cat("modifiers")}>
        <div>
          <div
            style={{ backgroundColor: "#" + colors[8].color }}
            className="price"
          >
            <p
              style={{
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              934 ₽
            </p>
            <p
              style={{
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              Добавить в заказ
            </p>
          </div>
          <ParamsGroup params={params} colors={colors} fonts={fonts} />
        </div>
        <ModifiersList
          modifies={modifiers_list}
          colors={colors}
          fonts={fonts}
        />
      </div>
    </div>
  );
};
export default OrderComponent;
