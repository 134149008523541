// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
}
.categories > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: -webkit-fill-available;
  padding: 5px 15px;
  border: 1px solid;
  border-radius: 5px;
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/components/categories-group-component/categories-group-component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".categories {\n  width: 150px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 20px;\n\n  > div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 14px;\n    width: -webkit-fill-available;\n    padding: 5px 15px;\n    border: 1px solid;\n    border-radius: 5px;\n    width: 70px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
