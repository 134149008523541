class BaseColor {
    id?:number;
  name?: string;
  color?: string;
  constructor(id:number,name: string, color: string = "fff") {
    this.id =id;
    this.name = name;
    this.color = color;
  }
}
export default BaseColor;

