// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-vertical {
  display: flex;
  flex-direction: column;
}
.list-vertical > div {
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  align-items: center;
}
.list-vertical-modif {
  display: flex;
  margin: 0px;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100px;
  height: 40px;
  padding: 0px;
  border: 1px solid;
  border-radius: 5px;
}
.list-vertical-modif p {
  line-height: 1.3vh;
  text-align: center;
}
.list-vertical-modif > div {
  flex-direction: row;
  justify-content: center;
  display: flex;
}
.list-vertical-modif > div > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 63px;
  bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-vertical/modifiers-list-component/modifiers-list-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAEJ;AAAE;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,kBAAA;AAGN;AAAI;EACE,mBAAA;EACA,uBAAA;EACA,aAAA;AAEN;AADM;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;AAGR","sourcesContent":[".list-vertical {\n  display: flex;\n  flex-direction: column;\n  > div {\n    display: flex;\n    flex-direction: row;\n    column-gap: 3px;\n    align-items: center;\n  }\n  &-modif {\n    display: flex;\n    margin: 0px;\n    flex-direction: column;\n    margin-bottom: 10px;\n    width: 100px;\n    height: 40px;\n    padding: 0px;\n    border: 1px solid;\n    border-radius: 5px;\n    p {\n      line-height: 1.3vh;\n      text-align: center;\n    }\n\n    > div {\n      flex-direction: row;\n      justify-content: center;\n      display: flex;\n      > div {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        width: 63px;\n        bottom: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
