// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-order-vertical {
  display: grid;
  grid-template-rows: 1fr;
  height: 800px;
}
.list-order-vertical > div {
  display: flex;
  padding-left: 20px;
}
.list-order-vertical > div:first-child {
  padding: 30px 10px;
  column-gap: 10px;
}
.list-order-vertical > img {
  width: 100%;
  height: 100px;
}
.list-order-vertical .categories {
  row-gap: 5px;
}
.list-order-vertical .categories > div {
  border-radius: 3px;
  width: 70px;
  height: 30px;
}
.list-order-vertical-basket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75px;
}
.list-order-vertical-basket > div {
  display: flex;
  flex-direction: column;
}
.list-order-vertical-bottom {
  height: 60px;
  margin-top: 40px;
  flex-direction: row;
}
.list-order-vertical-bottom :first-child > div:nth-child(2) {
  width: 69px;
  display: flex;
  position: relative;
  left: -20px;
  top: -5px;
  text-align: center;
}
.list-order-vertical-bottom .arrow {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -10px;
}
.list-order-vertical-bottom .pay_button {
  border-radius: 3px;
  border: none;
  height: 40px;
  margin: 10px 0px 0px 100px;
  width: 170px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-vertical/list-orders-component/list-orders-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EAEA,aAAA;AAAF;AACE;EACE,aAAA;EACA,kBAAA;AACJ;AACE;EACE,kBAAA;EACA,gBAAA;AACJ;AACE;EACE,WAAA;EACA,aAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAAJ;AACI;EACE,aAAA;EACA,sBAAA;AACN;AAGE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAEI;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;AAAN;AAEI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AAAN;AAEI;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;EACA,YAAA;AAAN","sourcesContent":[".list-order-vertical {\n  display: grid;\n  grid-template-rows: 1fr;\n\n  height: 800px;\n  > div {\n    display: flex;\n    padding-left: 20px;\n  }\n  > div:first-child {\n    padding: 30px 10px;\n    column-gap: 10px;\n  }\n  > img {\n    width: 100%;\n    height: 100px;\n  }\n\n  .categories {\n    row-gap: 5px;\n  }\n  .categories > div {\n    border-radius: 3px;\n    width: 70px;\n    height: 30px;\n  }\n  &-basket {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 75px;\n    > div {\n      display: flex;\n      flex-direction: column;\n     \n    }\n  }\n  &-bottom {\n    height: 60px;\n    margin-top: 40px;\n    flex-direction: row;\n    :first-child > div:nth-child(2) {\n      width: 69px;\n      display: flex;\n      position: relative;\n      left: -20px;\n      top: -5px;\n      text-align: center;\n    }\n    .arrow {\n      width: 25px;\n      height: 25px;\n      border-radius: 20px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      position: relative;\n      top: -10px;\n    }\n    .pay_button {\n      border-radius: 3px;\n      border: none;\n      height: 40px;\n      margin: 10px 0px 0px 100px;\n      width: 170px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
