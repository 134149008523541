// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-order {
  width: 85px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  box-shadow: -1px 2px 3px #3a3a3a;
  border-radius: 5px;
}
.card-order-img {
  height: 65px;
  width: 80px;
}
.card-order p {
  margin: 0px;
  margin-top: 3px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/template-ansoftbase/card-order-component/card-order-component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,gCAAA;EACA,kBAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AAAE;EACE,WAAA;EACA,eAAA;EACA,kBAAA;AAEJ","sourcesContent":[".card-order {\n  width: 85px;\n  height: 130px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 4px;\n  box-shadow: -1px 2px 3px #3a3a3a;\n  border-radius: 5px;\n  &-img {\n    height: 65px;\n    width: 80px;\n  }\n  p {\n    margin: 0px;\n    margin-top: 3px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
