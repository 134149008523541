class ItemForm {
  name: string = "";
  required: boolean = false;
  type: TypeItem = TypeItem.String;
  value: string = "";
  variants: string[] = [];
  description: string = "";
  isError: boolean = false;
  children: ItemForm[] = [];
  parent: string = "";
  xml: any = "";
}
export default ItemForm;

export enum TypeItem {
  String = "String",
  Combo = "Combo",
  Bool = "Bool",
  Time = "Time",
  Radio = "Radio",
}
