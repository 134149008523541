import { FC, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { cn } from "@bem-react/classname";
import "./mini-card-order-component.scss";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
const cat = cn("mini-card-order");
const logo = require("./../../shared/imges/orderImg.png");

type CardOrderProps = {
  colors: BaseColor[];
  order: Order;
};
const MiniCardOrderComponent: FC<CardOrderProps> = ({ colors, order }) => {
  return (
    <div className={cat()}>
      <div style={{ backgroundColor: "#" + colors[1].color }}>
        <div>
          <p style={{ color: "#" + colors[2].color }}>{order.name}</p>
        </div>
        <div>
          <img src={logo} className={cat("img")}></img>
        </div>
        <div className={cat("count")} style={{ backgroundColor: "#" + colors[8].color }}>
          <span style={{ color: "#" + colors[9].color }} >-</span>
          <span style={{ color: "#" + colors[9].color }}>1</span>
          <span style={{ color: "#" + colors[9].color }}>+</span>
        </div>
      </div>
      <button style={{ backgroundColor: "#" + colors[10].color, color: "#" + colors[11].color }}>Настроить</button>
    </div>
  );
};
export default MiniCardOrderComponent;
