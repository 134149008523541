// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-card-order {
  margin-top: 4px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  line-height: 1;
  row-gap: 10px;
}
.mini-card-order-count {
  height: 25px;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  border: 1px;
  border-radius: 15px;
  align-items: center;
  background: red;
  margin: 5px 0px;
}
.mini-card-order > button {
  height: 21px;
  border: 1px;
  border-radius: 5px;
}
.mini-card-order > div {
  width: 98px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 3px 8px;
  line-height: 1;
  border-radius: 5px;
}
.mini-card-order-img {
  width: 30px;
}
.mini-card-order p {
  margin: 0px;
  margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-ansoftbase/mini-card-order-component/mini-card-order-component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACJ;AACE;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AACE;EACE,WAAA;AACJ;AACE;EACE,WAAA;EACA,eAAA;AACJ","sourcesContent":[".mini-card-order {\n  margin-top: 4px;\n  margin-left: 4px;\n  display: flex;\n  flex-direction: column;\n  line-height: 1;\n  row-gap: 10px;\n\n  &-count {\n    height: 25px;\n    display: flex;\n    justify-content: center;\n    column-gap: 20px;\n    border: 1px;\n    border-radius: 15px;\n    align-items: center;\n    background: red;\n    margin: 5px 0px;\n  }\n  >button{\n    height: 21px;\n    border: 1px;\n    border-radius: 5px;\n  }\n  > div {\n    width: 98px;\n    display: flex;\n    flex-direction: column;\n    row-gap: 5px;\n    padding: 3px 8px;\n    line-height: 1;\n    border-radius: 5px;\n  }\n  &-img {\n    width: 30px;\n  }\n  p {\n    margin: 0px;\n    margin-top: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
