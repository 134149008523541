import { cn } from "@bem-react/classname";
import "./list-orders-with-basket-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";
import MiniCardOrderComponent from "../mini-card-order-component/mini-card-order-component";
import BaseFont from "../../../consts/base-font";
import CategoriesGroup from "../categories-group-component/categories-group-component";
import OrdersGroup from "../orders-group-component/orders-group-component";

const cat = cn("list-order-with-basket");
const categories = [
  "Пицца",
  "Бургеры",
  "Салаты",
  "Супы",
  "Соусы",
  "Десерты",
  "Напитки",
];
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];
type ListOrderProps = {
  colors: BaseColor[];
  img: any;
  fonts: BaseFont[];

  fontSizes: number[];
  selectIndex: number;
};
const ListOrdersWithBasketComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  fontSizes,
  selectIndex,
  img,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id="ListOrdersWithBasketComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={img} />
      <div>
        <CategoriesGroup
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          categories={categories}
          colors={colors}
          fonts={fonts}
        />
        <OrdersGroup
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          orders={orders}
          colors={colors}
          fonts={fonts}
        />
      </div>
      <div
        className={cat("bottom", [selectIndex == 8 ? "backlight" : ""])}
        style={{ backgroundColor: "#" + colors[8].color }}
      >
        <div className="orders">
          <MiniCardOrderComponent
            selectIndex={selectIndex}
            fontSizes={fontSizes}
            colors={colors}
            order={orders[0]}
          />
          <MiniCardOrderComponent
            fontSizes={fontSizes}
            selectIndex={selectIndex}
            colors={colors}
            order={orders[1]}
          />
        </div>
        <div className="top">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <g fill="none" fillRule="nonzero">
                <path d="M24 0v24H0V0h24ZM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018Zm.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01-.184-.092Z" />
                <path
                  fill={"#" + colors[9].color}
                  d="M18 3a3 3 0 0 1 2.995 2.824L21 6v12a3 3 0 0 1-2.824 2.995L18 21H6a3 3 0 0 1-2.995-2.824L3 18V6a3 3 0 0 1 2.824-2.995L6 3h12Zm0 2H6a1 1 0 0 0-.993.883L5 6v12a1 1 0 0 0 .883.993L6 19h12a1 1 0 0 0 .993-.883L19 18V6a1 1 0 0 0-.883-.993L18 5Zm-8.121 5.293L12 12.414l2.121-2.121a1 1 0 0 1 1.415 1.414l-2.829 2.829a1 1 0 0 1-1.414 0l-2.829-2.829a1 1 0 1 1 1.415-1.414Z"
                />
              </g>
            </svg>
            <span
              className={selectIndex == 9 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              Свернуть заказ
            </span>
          </div>
          <div>
            <span
              className={selectIndex == 9 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              0 ₽
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.594 23.258l-.012.002-.071.035-.02.004-.014-.004-.071-.036c-.01-.003-.019 0-.024.006l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113-.014.002-.184.093-.01.01-.003.011.018.43.005.012.008.008.201.092c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.003-.011.018-.43-.003-.012-.01-.01z" />
                <path
                  fill={"#" + colors[9].color}
                  d="M15.894 3.553 17.618 7H20a2 2 0 0 1 2 2v1a2 2 0 0 1-1.157 1.814l-1.519 6.837A3 3 0 0 1 16.396 21H7.604a3 3 0 0 1-2.928-2.35l-1.52-6.836A2 2 0 0 1 2 10V9a2 2 0 0 1 2-2h2.382l1.724-3.447a1 1 0 0 1 1.788.894L8.618 7h6.764l-1.276-2.553a1 1 0 1 1 1.788-.894M18.754 12H5.246l1.381 6.217a1 1 0 0 0 .976.783h8.792a1 1 0 0 0 .976-.783zM20 9H4v1h16z"
                />
              </g>
            </svg>
            <span
              className={selectIndex == 9 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              2
            </span>
          </div>
        </div>
        <div
          className={"buttons"}
          style={{ backgroundColor: "#" + colors[8].color }}
        >
          <button
            className={
              selectIndex == 12
                ? "backlight"
                : selectIndex == 13
                ? "backlighttext"
                : ""
            }
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              backgroundColor: "#" + colors[12].color,
              color: "#" + colors[13].color,
            }}
          >
            С собой
          </button>
          <button
            className={
              "pay_button " +
              (selectIndex == 12
                ? "backlight"
                : selectIndex == 13
                ? "backlighttext"
                : "")
            }
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              backgroundColor: "#" + colors[12].color,
              color: "#" + colors[13].color,
            }}
          >
            Оплатить
          </button>
          <button
            className={
              selectIndex == 12
                ? "backlight"
                : selectIndex == 13
                ? "backlighttext"
                : ""
            }
            style={{
              fontSize: fontSizes[0] + "px",
              fontFamily: fonts[0].name,
              backgroundColor: "#" + colors[12].color,
              color: "#" + colors[13].color,
            }}
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
};
export default ListOrdersWithBasketComponent;
