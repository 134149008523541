const measureText = (text: any, font: any) => {
  const canvas = document.createElement("canvas");
  const context: any = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
};

export default function normalizeFontSize(
  originalSize: number,
  originalFont: any,
  targetFont: string
) {
  const testText = "Ваш текст";
  const originalWidth = measureText(
    testText,
    `${originalSize}px ${originalFont}`
  );
  const targetWidth = measureText(testText, `${originalSize}px ${targetFont}`);

  return (originalSize * targetWidth) / originalWidth;
}
