import { cn } from "@bem-react/classname";
import "./order-result-component.scss";
import { FC } from "react";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
import ResultItemComponent from "../result-item-component/result-item-component";
import BaseFont from "../../consts/base-font";
import { fonts } from "pdfmake/build/pdfmake";

const img = require("./../../shared/imges/orderImg.png");
const cat = cn("order_result");
type ListOrderProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
};
const OrderResultComponent: FC<ListOrderProps> = ({ colors, fonts, image }) => {
  return (
    <div
      className={cat()}
      id="OrderResultComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <img src={image}></img>
      <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>
        Проверьте заказ перед оплатой
      </p>
      <div className={cat("goods")}>
        <ResultItemComponent fonts={fonts} colors={colors} />
        <ResultItemComponent fonts={fonts} colors={colors} />
        <ResultItemComponent fonts={fonts} colors={colors} />
      </div>
      <div className={cat("auth")}>
        <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>
          Для авторизации в бонусной системе нажмите кнопку и введите
        </p>
        <button
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          Авторизация
        </button>
      </div>
      <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>
        Сумма заказа: 1024 ₽
      </p>
      <div className={cat("buttons")}>
        <button
          className="back"
          style={{
            fontFamily: fonts[0].name,
            borderColor: "#" + colors[10].color,
            color: "#" + colors[10].color,
          }}
        >
          Назад
        </button>
        <button
          style={{
            fontFamily: fonts[0].name,
            backgroundColor: "#" + colors[10].color,
            color: "#" + colors[11].color,
          }}
        >
          Оплатить
        </button>
      </div>
    </div>
  );
};
export default OrderResultComponent;
