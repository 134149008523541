import React, { FC } from "react";
import "./modal-component.scss";
type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: any;
};
const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &#10006;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
