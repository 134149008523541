// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result_item-vertical {
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  column-gap: 10px;
  box-shadow: -1px 1px 4px #3a3a3a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.result_item-vertical p {
  margin: 0px;
}
.result_item-vertical img {
  width: 50px;
}
.result_item-vertical > div {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.result_item-vertical > .res {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.result_item-vertical > .res > svg {
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-vertical/result-item-component/result-item-component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAEJ;AADI;EACE,iBAAA;AAGN","sourcesContent":[".result_item-vertical {\n  border: 1px solid;\n  padding: 10px;\n  border-radius: 5px;\n  column-gap: 10px;\n  box-shadow: -1px 1px 4px #3a3a3a;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: -webkit-fill-available;\n  p {\n    margin: 0px;\n  }\n  img {\n    width: 50px;\n  }\n  > div {\n    display: flex;\n    flex-direction: row;\n    column-gap: 10px;\n  }\n  > .res {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    > svg {\n      margin-left: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
