class Modifier {
  id?: number;
  name?: string;
  price?: number | undefined;
  constructor(id: number, name: string, price?: number | undefined) {
    this.id = id;
    this.name = name;
    this.price = price;
  }
}
export default Modifier;
