import { FC } from "react";
import { cn } from "@bem-react/classname";
import "./form-input-component.scss";
import InputMask from "react-input-mask";
type FormInputProps = {
  title: string;
  text: string;
  isError: boolean;
  time?: boolean;
  isRequired?: boolean;
  helper?: string;
  OnChange: (value: string) => void;
};

const cat = cn("text-form");
const FormInput: FC<FormInputProps> = ({
  time,
  helper,
  isRequired,
  isError,
  title,
  text,
  OnChange,
}) => {

  return (
    <div className={cat()} title={helper ? helper : ""}>
      <div className="input-group">
        <label className="input-underlined">
          {time == false ? (
            <input
              required
              value={text}
              onChange={(e) => OnChange(e.target.value)}
            />
          ) : (
            <InputMask
              key={"mask" + title}
              readOnly={false}
              onFocus={(e) => {
                e.target.removeAttribute("readonly");
              }}
              mask="99:99"
              pattern="d{.}`m{.}`Y"
              required
              value={text}
              onChange={(e) => OnChange(e.target.value)}
            />
          )}

          <span className={isError ? "input-label error" : "input-label"}>
            {title}
            <span className="required">{isRequired == true ? " *" : ""}</span>
          </span>
          {/* <p className="input-helper">{helper}</p> */}
        </label>
      </div>
    </div>
  );
};

export default FormInput;
