// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  flex-flow: wrap;
  column-gap: 10px;
  padding: 5px;
}
body {
  background-color: #282c34;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".App {\n  display: flex;\n  flex-flow: wrap;\n  column-gap: 10px;\n  padding: 5px;\n}\nbody {\n  background-color: #282c34;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
