// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  max-width: calc(100vw - 430px);
  min-width: min-content;
  height: 800px;
  display: flex;
  flex: 1 1;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: center;
  padding: 5px;
  overflow-y: auto;
  background: #bfbfbf;
  border: 1px;
  border-radius: 10px;
}
.page > div {
  box-shadow: 2px 5px 6px grey;
  min-width: 450px;
  max-width: 450px;
  width: 450px;
  height: 800px;
}
.page > div > img {
  width: 100%;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/page-component/page-component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAAE;EACE,4BAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AAEJ;AADI;EACE,WAAA;EACA,aAAA;AAGN","sourcesContent":[".page {\n    max-width: calc(100vw - 430px);\n    min-width: min-content;\n    height: 800px;\n    display: flex;\n    flex: 1;\n    column-gap: 10px;\n    flex-wrap: wrap;\n    row-gap: 10px;\n    justify-content: center;\n    padding: 5px;\n    overflow-y: auto;\n    background: #bfbfbf;\n    border: 1px;\n    border-radius: 10px;  \n  > div {\n    box-shadow: 2px 5px 6px grey;\n    min-width: 450px;\n    max-width: 450px;\n    width: 450px;\n    height: 800px;\n    > img {\n      width: 100%;\n      height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
