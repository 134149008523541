// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_result {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.order_result > p:first-child {
  margin-top: 20px;
  margin-left: 10px;
}
.order_result-goods {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.order_result-buttons {
  display: flex;
  flex-direction: row;
  padding-left: 34px;
  justify-content: center;
  column-gap: 10px;
}
.order_result-buttons > button {
  width: 150px;
  height: 30px;
  border: 1px;
  border-radius: 5px;
}
.order_result-buttons .back {
  background-color: transparent;
  border: 1px solid;
}
.order_result-auth {
  display: flex;
  flex-direction: row;
  padding: 0px 30px;
  align-items: center;
  column-gap: 20px;
  margin-top: 218px;
}
.order_result-auth > p {
  width: 196px;
  font-size: 12px;
}
.order_result-auth > button {
  width: 150px;
  height: 30px;
  border: 1px;
  border-radius: 5px;
}
.order_result > p {
  text-align: start;
  padding-right: 60px;
  padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/order-result-component/order-result-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;AACF;AAAE;EACE,gBAAA;EACA,iBAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AAAI;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAEN;AAAI;EACE,6BAAA;EACA,iBAAA;AAEN;AACE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAAI;EACE,YAAA;EACA,eAAA;AAEN;AAAI;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAEN;AACE;EACE,iBAAA;EACA,mBAAA;EAAoB,kBAAA;AAExB","sourcesContent":[".order_result {\n  display: flex;\n  box-sizing: border-box;\n  flex-direction: column;\n  > p:first-child {\n    margin-top: 20px;\n    margin-left: 10px;\n  }\n\n  &-goods {\n    display: flex;\n    flex-direction: column;\n    row-gap: 10px;\n    padding: 10px;\n    box-sizing: border-box;\n  }\n  &-buttons {\n    display: flex;\n    flex-direction: row;\n    padding-left: 34px;\n    justify-content: center;\n    column-gap: 10px;\n    > button {\n      width: 150px;\n      height: 30px;\n      border: 1px;\n      border-radius: 5px;\n    }\n    .back{\n      background-color: transparent;\n      border: 1px solid;\n    }\n  }\n  &-auth {\n    display: flex;\n    flex-direction: row;\n    padding: 0px 30px;\n    align-items: center;\n    column-gap: 20px;\n    margin-top: 218px;\n    > p {\n      width: 196px;\n      font-size: 12px;\n    }\n    > button {\n      width: 150px;\n      height: 30px;\n      border: 1px;\n      border-radius: 5px;\n    }\n  }\n  > p {\n    text-align: start;\n    padding-right: 60px;padding-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
