// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check {
  font-size: 15px;
  display: flex;
  align-items: center;
  color: var(--color-ink-medium-contrast);
  column-gap: 5px;
}
.check-placeholder {
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/bool-component/bool-component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uCAAA;EACA,eAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".check {\n  font-size: 15px;\n  display: flex;\n  align-items: center;\n  color: var(--color-ink-medium-contrast);\n  column-gap: 5px;\n  &-placeholder {\n    margin: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
