// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-content .button {
  width: 150px;
  height: 30px;
  margin-top: 10px;
  border: 1px;
  align-self: end;
  border-radius: 5px;
  color: whitesmoke;
  background-color: #828282;
  box-shadow: 2px 2px 2px grey;
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content p {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/modal-component/modal-component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yCAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,yBAAA;EACA,4BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal-content {\n  position: relative;\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  width: 400px;\n  display: flex;\n  flex-direction: column;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.close-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.modal-content .button {\n  width: 150px;\n  height: 30px;\n  margin-top: 10px;\n  border: 1px;\n  align-self: end;\n  border-radius: 5px;\n  color: whitesmoke;\n  background-color: #828282;\n  box-shadow: 2px 2px 2px grey;\n}\n\n.modal-content h2 {\n  margin-bottom: 10px;\n}\n\n.modal-content p {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
