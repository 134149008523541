import { FC, useEffect, useRef, useState } from "react";
import { cn } from "@bem-react/classname";
import "./table-page-component.scss";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ListOrdersComponent from "../../template-table/list-orders-component/list-orders-component";
import ListOrdersWithBasketComponent from "../../template-table/list-orders-with-basket-component/list-orders-with-basket-component";
import OrderComponent from "../../template-table/order-component/order-component";
import OrderResultComponent from "../../template-table/order-result-component/order-result-component";
import ListOrdeWithModalComponent from "../../template-table/list-orders-with-modal-component/list-orders-with-modal-component";

const cat = cn("table");
type PageProps = {
  colors: BaseColor[];
  fonts: BaseFont[];
  banner: any;
  stub: any;
  selectIndex: number;
  screen: number;
  fontSizes: number[];
};
const TablePageComponent: FC<PageProps> = ({
  colors,
  fonts,
  banner,
  stub,
  selectIndex,
  screen,
  fontSizes,
}) => {
  const targetRef = useRef(null);
  const pageRef = useRef(null);
  const scrollToTarget = () => {
    if (targetRef.current) {
      (targetRef.current as any).scrollIntoView({ behavior: "smooth" });
    }
  };

  const [posTop, setPosTop] = useState(0);

  const checkPosition = () => {
    if (targetRef.current && pageRef.current) {
      const rect = (targetRef.current as any).getBoundingClientRect();
      const containerRect = (pageRef.current as any).getBoundingClientRect();

      if (rect.top < containerRect.top + containerRect.height / 2) {
        setPosTop(1);
      } else {
        setPosTop(-1);
      }
    }
  };

  useEffect(() => {
    checkPosition();
    window.addEventListener("scroll", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
    };
  }, [screen]);
  return (
    <div className={cat()} ref={pageRef}>
      <div className="bottom_screen">
        {" "}
        <button onClick={scrollToTarget}>
          <span>{posTop == 1 ? "⬆" : posTop == -1 ? "⬇" : ""}</span>
        </button>
      </div>
      <div className="item" ref={screen == 0 ? targetRef : null}>
        <ListOrdersComponent
          fontSizes={fontSizes}
          stub={stub}
          selectIndex={selectIndex}
          img={banner}
          id="ListOrdersComponent"
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className="item" ref={screen == 1 ? targetRef : null}>
        <ListOrdersWithBasketComponent
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          img={banner}
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className="item" ref={screen == 4 ? targetRef : null}>
        <OrderComponent
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          image={banner}
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className="item" ref={screen == 3 ? targetRef : null}>
        <OrderResultComponent
          selectIndex={selectIndex}
          fontSizes={fontSizes}
          image={banner}
          fonts={fonts}
          colors={colors}
        />
      </div>
      <div className="item" ref={screen == 4 ? targetRef : null}>
        <ListOrdeWithModalComponent
          selectIndex={selectIndex}
          fontSizes={fontSizes}
          image={banner}
          fonts={fonts}
          colors={colors}
        />
      </div>
    </div>
  );
};
export default TablePageComponent;
