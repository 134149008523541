// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-page {
  position: relative;
  height: 800px;
}
.modal-page-modal {
  top: 0px;
  display: flex;
  position: absolute;
  background-color: rgba(184, 184, 184, 0.6);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.modal-page-modal > div {
  border: 1px;
  border-radius: 15px;
  width: 350px;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-page-modal > div > p {
  padding: 0px 20px;
  font-size: 20px;
  text-align: center;
  margin-bottom: 60px;
}
.modal-page-modal .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 10px;
}
.modal-page-modal .buttons > button {
  width: 150px;
  height: 30px;
  border: 1px;
  border-radius: 5px;
}
.modal-page-modal .buttons .back {
  background-color: transparent;
  border: 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/list-orders-with-modal-component/list-orders-with-modal-component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;AACF;AAAE;EACE,QAAA;EACA,aAAA;EACA,kBAAA;EACA,0CAAA;EACA,6BAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;AAEJ;AADI;EACE,WAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAGN;AAFM;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;AAIR;AADI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAGN;AAFM;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAIR;AAFM;EACE,6BAAA;EACA,iBAAA;AAIR","sourcesContent":[".modal-page {\n  position: relative;\n  height: 800px;\n  &-modal {\n    top: 0px;\n    display: flex;\n    position: absolute;\n    background-color: rgba(184, 184, 184, 0.6);\n    width: -webkit-fill-available;\n    height: -webkit-fill-available;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 100px;\n    > div {\n      border: 1px;\n      border-radius: 15px;\n      width: 350px;\n      height: 260px;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      > p {\n        padding: 0px 20px;\n        font-size: 20px;\n        text-align: center;\n        margin-bottom: 60px;\n      }\n    }\n    .buttons {\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      column-gap: 10px;\n      > button {\n        width: 150px;\n        height: 30px;\n        border: 1px;\n        border-radius: 5px;\n      }\n      .back {\n        background-color: transparent;\n        border: 1px solid;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
