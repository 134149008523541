import { cn } from "@bem-react/classname";
import "./list-orders-with-modal-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";
import ListOrdersComponent from "../list-orders-component/list-orders-component";

const cat = cn("modal-page-horizontal");
type ListOrdeWithModalProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
  fontSizes: number[];
  selectIndex: number;
};
const ListOrdeWithModalComponent: FC<ListOrdeWithModalProps> = ({
  colors,
  image,
  fonts,
  selectIndex,
  fontSizes,
}) => {
  return (
    <div id="ListOrdeWithModalComponent" className={cat()}>
      <ListOrdersComponent
        fontSizes={fontSizes}
        selectIndex={selectIndex}
        img={image}
        fonts={fonts}
        colors={colors}
      />
      <div className={cat("modal")}>
        <div
          className={selectIndex == 0 ? "backlight" : ""}
          style={{ backgroundColor: "#" + colors[0].color }}
        >
          <p
            className={selectIndex == 6 ? "backlighttext" : ""}
            style={{
              fontSize: fontSizes[2] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
            }}
          >
            Вы дествительно хотите отменить заказ и перейти к начальному экрану?
          </p>
          <div className={cat("buttons")}>
            <button
              className={"back " + (selectIndex == 12 ? "backlight" : "")}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                borderColor: "#" + colors[12].color,
                color: "#" + colors[12].color,
              }}
            >
              Отменить
            </button>
            <button
              className={
                selectIndex == 12
                  ? "backlight"
                  : selectIndex == 13
                  ? "backlighttext"
                  : ""
              }
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                backgroundColor: "#" + colors[12].color,
                color: "#" + colors[13].color,
              }}
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListOrdeWithModalComponent;
