// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.result_item {
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  column-gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.result_item p {
  margin: 0px;
}
.result_item img {
  width: 50px;
}
.result_item > div {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.result_item .res {
  flex-direction: column;
}
.result_item .res > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-ansoftbase/result-item-component/result-item-component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6BAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,sBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAGN","sourcesContent":[".result_item {\n  border: 1px solid;\n  padding: 10px;\n  border-radius: 5px;\n  column-gap: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: -webkit-fill-available;\n  p {\n    margin: 0px;\n  }\n  img {\n    width: 50px;\n  }\n  > div {\n    display: flex;\n    flex-direction: row;\n    column-gap: 10px;\n  }\n  .res {\n    flex-direction: column;\n    > div {\n      display: flex;\n      flex-direction: row;\n      justify-content: space-between;\n      width: 40px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
