import { FC, useState } from "react";
import BaseColor from "../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./modifiers-group-component.scss";
import BaseFont from "../../consts/base-font";
import Modifier from "../../consts/modifier";

type ModifiersGroupProps = {
  sizes: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
};

const cat = cn("sizes");
const ModifiersGroup: FC<ModifiersGroupProps> = ({ sizes, colors, fonts }) => {
  return (
    <div className={cat()}>
      {sizes.map((c) => {
        return (
          <div
            key={c.id}
            style={{
              backgroundColor: c.id==0?"#" + colors[3].color:"",
              borderColor: "#" + colors[3].color,
            }}
          >
            <p
              style={{
                fontFamily: fonts[0].name,
                color: "#" + (c.id==0?colors[5].color:colors[4].color),
              }}
            >
              {c.name}
            </p>
            {c.price && (
              <p
                style={{
                  fontFamily: fonts[0].name,
                  color: "#" + (c.id==0?colors[5].color:colors[4].color),
                }}
              >
                {c.price}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ModifiersGroup;
