// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker > div {
  margin-bottom: 20px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 8px;
}
.color-picker-buttons {
  flex-direction: row !important;
}

.react-colorful {
  height: 125px !important;
  width: 300px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/color-picker-component/color-picker-component.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAEE;EACE,8BAAA;AAAJ;;AAGA;EACE,wBAAA;EACA,uBAAA;AAAF","sourcesContent":[".color-picker {\n  > div {\n    margin-bottom: 20px;\n    max-height: 800px;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    column-gap: 10px;\n    row-gap: 8px;\n  }\n  &-buttons {\n    flex-direction: row !important;\n  }\n}\n.react-colorful {\n  height: 125px !important;\n  width: 300px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
