import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./categories-group-component.scss";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";

type CategoriesGroupProps = {
  categories: string[];
  colors: BaseColor[];
  fonts: BaseFont[];
  selectIndex: number;

  fontSizes: number[];
};

const cat = cn("categories");
const CategoriesGroup: FC<CategoriesGroupProps> = ({
  categories,
  selectIndex,
  fontSizes,
  colors,
  fonts,
}) => {
  return (
    <div className={cat()}>
      {categories.map((c, i) => {
        return (
          <div
            className={
              selectIndex == 3 || selectIndex == 4
                ? "backlight"
                : selectIndex == 6
                ? "backlighttext"
                : ""
            }
            key={crypto.randomUUID()}
            style={{
              backgroundColor:
                i == 0 ? "#" + colors[5].color : "#" + colors[4].color,
              fontSize: fontSizes[1] + "px",
              fontFamily: fonts[0].name,
              color: "#" + colors[6].color,
              borderColor: "#" + colors[3].color,
            }}
          >
            {c}
          </div>
        );
      })}
    </div>
  );
};

export default CategoriesGroup;
