import { cn } from "@bem-react/classname";
import "./list-orders-with-basket-component.scss";
import { FC } from "react";
import BaseColor from "../../../consts/base-colors";
import Order from "../../../consts/order";
import BaseFont from "../../../consts/base-font";
import MiniCardOrderComponent from "../mini-card-order-component/mini-card-order-component";
import CategoriesGroup from "../../template-ansoftbase/categories-group-component/categories-group-component";
import OrdersGroup from "../orders-group-component/orders-group-component";

const cat = cn("list-order-with-basket-table");
const categories = [
  "Пицца",
  "Бургеры",
  "Салаты",
  "Супы",
  "Соусы",
  "Десерты",
  "Напитки",
];
const orders = [
  new Order(0, "Воппер Сибирский", 280),
  new Order(1, "Сибирский Чикен", 280),
  new Order(2, "Чикен Ролл", 280),
  new Order(3, "Шримп Ролл", 280),
  new Order(4, "Капучино", 280),
  new Order(5, "А4 Бокс", 280),
  new Order(6, "Картофель фри", 280),
  new Order(7, "Соус Сырный", 280),
  new Order(8, "Соус Томатный", 280),
  new Order(9, "Соус Цезарь", 280),
  new Order(10, "Соус Карри", 280),
];
type ListOrderProps = {
  colors: BaseColor[];
  img: any;
  fonts: BaseFont[];
  selectIndex: number;
  fontSizes: number[];
};
const ListOrdersWithBasketComponent: FC<ListOrderProps> = ({
  colors,
  fonts,
  selectIndex,
  img,
  fontSizes,
}) => {
  return (
    <div
      className={cat("", [selectIndex == 0 ? "backlight" : ""])}
      id="ListOrdersWithBasketComponent"
      style={{ backgroundColor: "#" + colors[0].color }}
    >
      <div>
        <CategoriesGroup
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          categories={categories}
          colors={colors}
          fonts={fonts}
        />
        <OrdersGroup
          fontSizes={fontSizes}
          selectIndex={selectIndex}
          orders={orders}
          colors={colors}
          fonts={fonts}
        />
      </div>
      <div
        className={cat("bottom", [selectIndex == 6 ? "backlight" : ""])}
        style={{ backgroundColor: "#" + colors[8].color }}
      >
        <div
          className="orders"
          style={{ backgroundColor: "#" + colors[0].color }}
        >
          <MiniCardOrderComponent
            fontSizes={fontSizes}
            fonts={fonts}
            selectIndex={selectIndex}
            colors={colors}
            order={orders[0]}
          />
          <MiniCardOrderComponent
            fontSizes={fontSizes}
            fonts={fonts}
            selectIndex={selectIndex}
            colors={colors}
            order={orders[1]}
          />
        </div>
        <div className={cat("buttons")}>
          <div className={cat("basket")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
            </svg>
            <div>
              <span
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[9].color,
                }}
                className={selectIndex == 9 ? "backlighttext" : ""}
              >
                В зале
              </span>
              <span
                className={selectIndex == 9 ? "backlighttext" : ""}
                style={{
                  fontFamily: fonts[0].name,
                  color: "#" + colors[9].color,
                }}
              >
                0 ₽
              </span>
            </div>
          </div>
          <div className="button-arrow">
            <div
              className="arrow"
              style={{
                color: "#" + colors[9].color,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
              </svg>
            </div>
            <div
              className={selectIndex == 9 ? "backlighttext" : ""}
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                color: "#" + colors[9].color,
              }}
            >
              Посмотреть заказ
            </div>
          </div>
          <div>
            <button
              className={
                "pay_button " +
                (selectIndex == 12
                  ? "backlight"
                  : selectIndex == 13
                  ? "backlighttext"
                  : "")
              }
              style={{
                fontSize: fontSizes[0] + "px",
                fontFamily: fonts[0].name,
                backgroundColor: "#" + colors[12].color,
                color: "#" + colors[13].color,
              }}
            >
              Оплатить
            </button>

            <button
              className={
                "pay_button " +
                (selectIndex == 12
                  ? "backlight"
                  : selectIndex == 13
                  ? "backlighttext"
                  : "")
              }
              style={{
                fontFamily: fonts[0].name,
                backgroundColor: "#" + colors[12].color,
                color: "#" + colors[13].color,
              }}
            >
              Отменить заказ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListOrdersWithBasketComponent;
