// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-table {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-left: 10px;
  height: 260px;
  overflow: hidden;
  padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/template-table/orders-group-component/orders-group-component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".orders-table {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 10px;\n  row-gap: 10px;\n  margin-left: 10px;\n  height: 260px;\n  overflow: hidden;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
