import { FC, useEffect, useState } from "react";
import "./bool-component.scss";
import { cn } from "@bem-react/classname";

type BoolProps = {
  placeholder: string;
  value?: string;
  isError?: boolean;
  helper?: string;
  onChange: (value: any) => void;
};
const cat = cn("check");
const BoolForm: FC<BoolProps> = ({
  placeholder,
  isError,
  value,
  helper,
  onChange,
}) => {
  const [valueCheck, setValueCheck] = useState<boolean>();
  useEffect(() => {
    if (
      (value == "Да" && valueCheck != true) ||
      (value == "Нет" && valueCheck != false)
    )
      setValueCheck(value && value == "Да" ? true : false);
  }, [value]);
  return (
    <div className={cat()} title={helper}>
      <input
        type="checkbox"
        checked={valueCheck}
        onChange={(v) => {
          onChange(Boolean(v.target.checked) == true ? "Да" : "Нет");
        }}
      />
      <p className={cat("placeholder")}>{placeholder}</p>
      {/* <p className="helper">{helper}</p> */}
    </div>
  );
};

export default BoolForm;
