import { cn } from "@bem-react/classname";
import "./list-orders-with-modal-component.scss";
import { FC } from "react";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
import BaseFont from "../../consts/base-font";
import ListOrdersComponent from "../list-orders-component/list-orders-component";

const cat = cn("modal-page");
type ListOrdeWithModalProps = {
  colors: BaseColor[];
  image: any;
  fonts: BaseFont[];
};
const ListOrdeWithModalComponent: FC<ListOrdeWithModalProps> = ({
  colors,
  image,
  fonts,
}) => {
  return (
    <div id="ListOrdeWithModalComponent" className={cat()}>
      <ListOrdersComponent img={image} fonts={fonts} colors={colors} />
      <div className={cat("modal")}>
        <div style={{ backgroundColor: "#" + colors[0].color }}>
          <p
            style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}
          >
            Вы дествительно хотите отменить заказ и перейти к начальному экрану?
          </p>
          <div className="buttons">
            <button
              className="back"
              style={{
                fontFamily: fonts[0].name,
                borderColor: "#" + colors[10].color,
                color: "#" + colors[10].color,
              }}
            >
              Отменить
            </button>
            <button
              style={{
                fontFamily: fonts[0].name,
                backgroundColor: "#" + colors[10].color,
                color: "#" + colors[11].color,
              }}
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListOrdeWithModalComponent;
