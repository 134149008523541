import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./page-component.scss";
import BaseColor from "../../consts/base-colors";
import ListOrdersComponent from "../list-orders-component/list-orders-component";
import ListOrdersWithBasketComponent from "../list-orders-with-basket-component/list-orders-with-basket-component";
import OrderComponent from "../order-component/order-component";
import OrderResultComponent from "../order-result-component/order-result-component";
import ListOrdeWithModalComponent from "../list-orders-with-modal-component/list-orders-with-modal-component";
import BaseFont from "../../consts/base-font";

const cat = cn("page");
type PageProps = {
  colors: BaseColor[];
  fonts: BaseFont[];
  banner: any;
  stub: any;
};
const PageComponent: FC<PageProps> = ({ colors, fonts, banner, stub }) => {
  
  return (
    <div className={cat()}>
      <ListOrdersComponent
        stub={stub}
        img={banner}
        id="ListOrdersComponent"
        fonts={fonts}
        colors={colors}
      />
      <ListOrdersWithBasketComponent
        img={banner}
        fonts={fonts}
        colors={colors}
      />
      <OrderComponent image={banner} fonts={fonts} colors={colors} />
      <OrderResultComponent image={banner} fonts={fonts} colors={colors} />
      <ListOrdeWithModalComponent
        image={banner}
        fonts={fonts}
        colors={colors}
      />
    </div>
  );
};
export default PageComponent;
