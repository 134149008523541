import { FC, useState } from "react";
import { cn } from "@bem-react/classname";
import "./modifiers-list-component.scss";
import Modifier from "../../../consts/modifier";
import BaseColor from "../../../consts/base-colors";
import BaseFont from "../../../consts/base-font";

type ModifiersListProps = {
  modifies: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
  selectIndex: number;

  fontSizes: number[];
  text: string;
};

const cat = cn("list-vertical");
const ModifiersList: FC<ModifiersListProps> = ({
  modifies,
  colors,
  fontSizes,
  fonts,
  selectIndex,
  text,
}) => {
  return (
    <div className={cat()}>
      <p
        className={selectIndex == 6 ? "backlighttext" : ""}
        style={{
          fontSize: fontSizes[0] + "px",
          fontFamily: fonts[0].name,
          color: "#" + colors[6].color,
        }}
      >
        {text}
      </p>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
        </svg>
        {modifies.map((c) => {
          return (
            <div
              key={c.id}
              className={
                "list-vertical-modif " + (selectIndex == 3 ? "backlight" : "")
              }
              style={{ borderColor: "#" + colors[3].color }}
            >
              <p
                className={selectIndex == 6 ? "backlighttext" : ""}
                style={{
                  fontSize: fontSizes[0] + "px",
                  fontFamily: fonts[0].name,
                  color: "#" + colors[6].color,
                }}
              >
                {c.name}
              </p>
              <div>
                <div>
                  {" "}
                  <span
                    className={selectIndex == 6 ? "backlighttext" : ""}
                    style={{
                      fontSize: fontSizes[0] + "px",
                      fontFamily: fonts[0].name,
                      color: "#" + colors[6].color,
                    }}
                  >
                    -
                  </span>
                  <span
                    className={selectIndex == 6 ? "backlighttext" : ""}
                    style={{
                      fontSize: fontSizes[0] + "px",
                      fontFamily: fonts[0].name,
                      color: "#" + colors[6].color,
                    }}
                  >
                    0
                  </span>
                  <span
                    className={selectIndex == 6 ? "backlighttext" : ""}
                    style={{
                      fontSize: fontSizes[0] + "px",
                      fontFamily: fonts[0].name,
                      color: "#" + colors[6].color,
                    }}
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
        </svg>
      </div>
    </div>
  );
};

export default ModifiersList;
