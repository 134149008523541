// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.params {
  width: 104px;
  font-size: 12px;
  margin-top: 10px;
  padding: 20px 10px;
  border: 1px solid;
  border-radius: 10px;
  box-sizing: border-box;
}
.params > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/components/params-group-component/params-group-component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".params {\n  width: 104px;\n  font-size: 12px;\n  margin-top: 10px;\n  padding: 20px 10px;\n  border: 1px solid;\n  border-radius: 10px;\n  box-sizing: border-box;\n  > div {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
