import { FC, useState } from "react";
import BaseColor from "../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./orders-group-component.scss";
import BaseFont from "../../consts/base-font";
import Order from "../../consts/order";
import CardOrderComponent from "../card-order-component/card-order-component";

type CategoriesGroupProps = {
  orders: Order[];
  colors: BaseColor[];
  fonts: BaseFont[];
  stub?: any;
};

const cat = cn("orders");
const OrdersGroup: FC<CategoriesGroupProps> = ({
  orders,
  colors,
  fonts,
  stub,
}) => {
  return (
    <div className={cat()}>
      {orders.map((o, i) => {
        return (
          <CardOrderComponent
            fonts={fonts}
            key={crypto.randomUUID()}
            order={o}
            image={i == 0 && stub}
            colors={colors}
          />
        );
      })}
    </div>
  );
};

export default OrdersGroup;
