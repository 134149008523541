import { FC, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { cn } from "@bem-react/classname";
import "./color-picker-component.scss";
import BaseColor from "../../consts/base-colors";
import axios from "axios";
import { DonloadFiles } from "../../shared/helpers/create-colors-sheet";
import BaseFont from "../../consts/base-font";
import ColorInput from "../color-input-component/color-input-component";
import FontInput from "../font-input-component/font-input-component";
import TextInput from "../text-input-component/text-input-component";
import Button from "../button-component/button-component";
import { useNavigate } from "react-router-dom";
import SelectForm from "../select-component/select-component";
import FormFileInput from "../form-file-input-component/form-file-input-component";

const cat = cn("color-picker");
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Access-Control-Allow-Headers"] =
  "Origin, X-Requested-With, Content-Type, Accept";
axios.defaults.headers.post["Access-Control-Allow-Methods"] = "GET, POST";
type ColorPickerProps = {
  colors: BaseColor[];
  fonts: BaseFont[];
  setColors: (values: BaseColor[]) => void;
  setFonts: (values: BaseFont[]) => void;
  setBanner: (value: any) => void;
  setStub: (value: any) => void;
};

const list = ["Стандарт"];
const ColorPicker: FC<ColorPickerProps> = ({
  colors,
  setColors,
  setBanner,
  setStub,
  fonts,
  setFonts,
}) => {
  const [selectIndex, setSelectIndex] = useState<number>(0);
  const [nameClient, setNameClient] = useState<string>("");
  const [bannerClient, setBannerClient] = useState<any[]>([]);
  const [iconsClient, setIconsClient] = useState<any[]>([]);
  const [stubClient, setStubClient] = useState<any>();
  const [license, setLicense] = useState<string>(list[0]);
  const [error, setError] = useState<string | undefined>(undefined);
  let navigate = useNavigate();
  const OnChange = (index: number, value: string) => {
    colors[index].color = value;
    setColors([...colors]);
  };

  const ValidationData = () => {
    let result = true;
    colors.map((c) => {
      if (c.color == "") {
        setError("Некорректное значение цвета: " + c.name);
        result = false;
      }
    });
    fonts.map((f) => {
      if (f.name == "" || !f.name) {
        setError("Некорректное название шрифта: " + f.name);
        result = false;
      }
    });
    if (nameClient == "") {
      setError("Некорректное название проекта.");
      result = false;
    }
    return result;
  };
  const OnChangeFont = (index: number, value: string, file?: any) => {
    fonts[index].key = value;
    if (file) {
      fonts[index].name = value;
      fonts[index].file = file;
    }

    setFonts([...fonts]);
  };
  const OnChangeSelectColor = (value: string) => {
    value = value.replace("#", "");
    colors[selectIndex].color = value;
    setColors([...colors]);
  };
  useEffect(() => {
    if (error) {
      alert(error);
      setError(undefined);
    }
  }, [error]);
  const handleChange = (file: any) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBanner(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChangeStub = (file: any) => {
    if (file) {
      setStubClient(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setStub(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className={cat()}>
      <div>
        <TextInput
          title={"Название проекта/клиента"}
          text={nameClient}
          OnChange={setNameClient}
        />
        <FormFileInput
          text={"Баннеры"}
          OnChange={handleChange}
          OnChangeGroup={(f) => {
            setBannerClient(f);
          }}
          accept=".png"
          multiple={true}
        />
        <FormFileInput
          text={"Иконки"}
          accept=".png"
          OnChangeGroup={setIconsClient}
          multiple={true}
        />
        <FormFileInput
          text={"Фото-заглушка"}
          OnChange={handleChangeStub}
          accept=".png"
        />
        <SelectForm
          value={[list[0]]}
          placeholder={"Лицензия"}
          options={list}
          onChange={(e) => {
            setLicense(e);
          }}
        />

        {fonts.map((f: any) => {
          return (
            <FontInput
              OnChange={OnChangeFont}
              OnChangeString={OnChangeFont}
              font={f}
              key={"file" + f.id}
              text={f.id == 0 ? "основного шрифта" : "доп. шрифта"}
            />
          );
        })}

        <HexColorPicker
          color={colors[selectIndex].color}
          onChange={OnChangeSelectColor}
        />
        {colors.map((c: any, index) => {
          return (
            <ColorInput
              color={c}
              key={index + c.name}
              index={index}
              selectIndex={selectIndex}
              OnChange={OnChange}
              SetSelectIndex={setSelectIndex}
            />
          );
        })}
      </div>
      <div className={cat("buttons")}>
        <Button onClick={() => navigate("/")} text={"Перейти к чек-листу"} />
        <Button
          onClick={async () => {
            ValidationData() == true &&
              DonloadFiles(colors, fonts, nameClient, license, [
                ...bannerClient,
                stubClient,
                ...iconsClient,
              ]);
          }}
          text={"Сохранить"}
        />
      </div>
    </div>
  );
};
export default ColorPicker;
