import { FC, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { cn } from "@bem-react/classname";
import "./card-order-component.scss";
import BaseColor from "../../consts/base-colors";
import Order from "../../consts/order";
import BaseFont from "../../consts/base-font";
const cat = cn("card-order");
const logo = require("./../../shared/imges/orderImg.png");

type CardOrderProps = {
  colors: BaseColor[];
  order: Order;
  fonts: BaseFont[];
  image?: any;
};
const CardOrderComponent: FC<CardOrderProps> = ({
  colors,
  order,
  fonts,
  image,
}) => {
  return (
    <div className={cat()} style={{ backgroundColor: "#" + colors[1].color }}>
      <img src={image ? image : logo} className={cat("img")}></img>
      <p style={{ fontFamily: fonts[0].name, color: "#" + colors[2].color }}>
        {order.name}
      </p>
      <p style={{ fontFamily: fonts[0].name, color: "#" + colors[2].color }}>
        Цена: {order.price}
      </p>
    </div>
  );
};
export default CardOrderComponent;
