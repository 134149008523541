import { FC, useState } from "react";
import BaseColor from "../../consts/base-colors";
import { cn } from "@bem-react/classname";
import "./modifiers-list-component.scss";
import BaseFont from "../../consts/base-font";
import Modifier from "../../consts/modifier";

type ModifiersListProps = {
  modifies: Modifier[];
  colors: BaseColor[];
  fonts: BaseFont[];
};

const cat = cn("list");
const ModifiersList: FC<ModifiersListProps> = ({ modifies, colors, fonts }) => {
  return (
    <div className={cat()}>
      <p style={{ fontFamily: fonts[0].name, color: "#" + colors[4].color }}>
        Максимум 2, минимум 1
      </p>
      {modifies.map((c) => {
        return (
          <div
            className="modif"
            key={c.id}
            style={{ borderColor: "#" + colors[3].color }}
          >
            <p
              style={{
                fontFamily: fonts[0].name,
                color: "#" + colors[4].color,
              }}
            >
              {c.name}
            </p>
            <div>
              <span
                style={{
                  fontFamily: fonts[0].name,
                  color: "#" + colors[4].color,
                }}
              >
                {c.price} ₽
              </span>
              <div>
                {" "}
                <span
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  -
                </span>
                <span
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  1
                </span>
                <span
                  style={{
                    fontFamily: fonts[0].name,
                    color: "#" + colors[4].color,
                  }}
                >
                  +
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ModifiersList;
