import { FC, useEffect, useState } from "react";
import "./button-component.scss";
import { cn } from "@bem-react/classname";

type ButtonProps = {
  onClick: () => void;
  text: string;
};
const cat = cn("button");
const Button: FC<ButtonProps> = ({ onClick, text }) => {
  return (
    <button className={cat()} onClick={onClick}>
      {text}
    </button>
  );
};
export default Button;
